@import "src/app/global/variables.scss";
.PricingBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    padding-left: 214px;
    padding-right: 214px;

    margin-top: 150px;

    @media screen and (max-width: 1024px) {
        padding-left: 42px;
        padding-right: 42px;
        margin-top: 0px;
    }
}

.Illustration {
    position: absolute;
    top: -90px;
    left: 1100px;

    @media screen and (max-width: 767px) {
        top: -20px;
        left: 40%;
        z-index: 1;
    }

    @media screen and (min-width: 1600px) {
        left: auto;
        right: 250px;
    }
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;

    h2 {
        color: $black-blue;
    }
    p {
        color: $gray-color;
        font-size: $font-s;
    }

    margin-bottom: 42px;
    @media screen and (max-width: 767px) {
        align-items: start;
        max-width: 150px;
        margin-right: 250px;
    }
}

.cardsList {
    display: grid;
    gap: 40px;
    grid-template-columns: 2fr 2fr 2fr;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        display: grid;
        gap: 40px;
        grid-template-columns: 2fr 2fr;
    }
}

.freeTrial {
    position: relative;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 53px;
    border-radius: 8.276px;
    border: 1.294px solid #cecece;
    background: linear-gradient(
        90deg,
        #fff 1.3%,
        rgba(255, 255, 255, 0.3) 97.81%
    );
    box-shadow: 0px 10.348px 41.393px 0px
        rgba(59, 59, 59, 0.04);
    backdrop-filter: blur(9.93137264251709px);
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    min-width: 952px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        min-width: 300px;
        padding-bottom: 32px;
        padding-top: 32px;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        min-width: 0px;
    }
    @media screen and (min-width: 1920px) {
        max-width: 1190px;
    }
}

.titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 53px;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
}

.price {
    color: $black-blue;

    font-family: "Raleway";
    font-variant-numeric: lining-nums proportional-nums;

    font-size: 59.399px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dollar {
    color: $black-blue;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 29.699px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.period {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 29.699px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.PricingCard {
    position: relative;
    z-index: 10;
    border-radius: 8.276px;
    border: 1.294px solid #cecece;
    background: #fff;
    box-shadow: 0px 10.348px 41.393px 0px
        rgba(59, 59, 59, 0.04);
    padding: 22px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;

    height: calc(100% - 46px);

    h3 {
        color: $black-blue;
    }

    transition-duration: 500ms;

    &:hover {
        transform: scale(1.1);
    }

    &.mobile {
        &:hover {
            transform: scale(1);
        }
    }
    h4 {
        color: $primary-color;
        text-align: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: "Raleway";
        font-size: 16.971px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    @media screen and (min-width: 1800px) {
        padding: 22px 0px;
        max-width: 370px;
    }
}

.titleContainerOffer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
        font-size: 25px;
        color: $black-blue;
        margin-top: 17px;
    }
    p {
        font-size: 16px;
        margin-bottom: 39px;
        text-align: center;
        color: #666;
    }
}

.price {
    color: $black-blue;

    font-family: "Raleway";
    font-variant-numeric: lining-nums proportional-nums;

    font-size: 59.399px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dollar {
    color: $black-blue;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 29.699px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.period {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 29.699px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.prefers {
    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 16px;

    padding: 0;
}

.prefersItem {
    display: flex;
    justify-content: space-between;

    gap: 69px;

    color: #666;
    font-size: 16.971px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
}

.orderButton {
    &:hover {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: white;
    }
}

.vMark {
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
}

.xMark {
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
}

.titleContainer {
    color: $black-blue;
    text-align: center;
}
