@import "src/app/global/variables.scss";
.Navigation {
    color: $text-color;
    font-size: $font-s;

    display: flex;
    align-items: center;
    
}

.list {
    display: flex;
    list-style: none;

    gap: 32px;

    @media screen and (max-width: 1100px) {
        flex-direction: column;
    }

    @media screen and (min-width: 1100px) and (max-width: 1440px) {
        gap: 18px;
    }
}

.item {
    cursor: pointer;
    width: fit-content;

    padding-bottom: 8px;
    white-space: pre-line;
    p {
        mix-blend-mode: difference;
        transition-duration: 300ms;
        &:hover {
            transform: scale(1.05);
        }
    }

    &.active {
        border-bottom: 4px solid $primary-color;
    }

    @media screen and (min-width: 1100px) and (max-width: 1440px) {
        p {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 1100px) {
        p {
            mix-blend-mode: normal;
            
        }
        &.active {
            border-bottom: 4px solid $primary-color;
        }
        &.current {
            border-bottom: 0;
            max-width: fit-content;
        }
    }
}

.link {
    text-decoration: none;
    color: white;
}
