@import "src/app/global/variables.scss";
.controls {
    display: flex;
    gap: 16px;

    margin-top: 227px;
    margin-left: 222px;
    margin-right: 222px;

    @media screen and (max-width: 1024px) {
        margin-left: 24px;
        margin-right: 24px;
    }
}
.content {
    // background-color: red;
    margin-top: 256px;
    margin-left: 222px;
    margin-right: 222px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: 1250px) {
        margin-left: 30px;
        margin-right: 30px;
    }
    @media screen and (max-width: 1024px) {
        margin-left: 24px;
        margin-right: 24px;
        display: flex;
        flex-direction: column;
    }
}

.bgWave {
    position: absolute;
    width: 100%;
    height: 400px;
    z-index: -1;
    top: 0;
    left: 0;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 127px;
    color: white;

    h1{
        @media screen and (min-width: 1024px) {
            font-size: 48px;
        }
    }
}

.BlogPagination {
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

.footer {
    margin-top: 156px;
    position: relative;
}
